<template>
  <v-container>
    <kontakt-formular/>

    <v-card outlined class="mt-5" elevation="6">
      <v-card-title>
        So erreichen Sie mich
      </v-card-title>
      <v-card-body class="d-flex" style="height:100%">
        <div class="d-flex flex-column ml-5 mb-5 flex-grow-1 flex-shrink-1">
          <iframe width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
            src="https://www.openstreetmap.org/export/embed.html?bbox=12.485382556915285%2C49.64156377047432%2C12.491776943206789%2C49.644676215194934&amp;layer=mapnik&amp;marker=49.64312001770542%2C12.488579750061035"
            style="border: 1px solid black"
          />
          <small><a href="https://www.openstreetmap.org/?mlat=49.64312&amp;mlon=12.48858#map=18/49.64312/12.48858">Karte öffnen</a></small>
        </div>

        <div class="d-flex flex-column mx-5 justify-space-between"> 
          <div>
            RR - Rechtsanwalt Ring<br>
            Narzissenweg 9<br>
            92726 Waidhaus<br>
            Tel: <a href="tel:096523429978">09652/3429978</a><br>
            Fax: 09562/3429998<br>
            <a href="mailto:info@rr-raring.de">info@rr-raring.de</a><br><br>
          </div>
          
          <div class="mt-auto mb-9">
            <v-btn icon href="https://www.instagram.com/rechtsanwaltring" target="_blank">
              <v-icon size="50" color="black">mdi-instagram</v-icon>
            </v-btn>
            <v-btn class="ml-6 mr-6" icon href="https://www.facebook.com/RechtsanwaltRing" target="_blank">
              <v-icon size="50" color="black">mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon href="https://www.twitter.com/danielring91" target="_blank">
              <v-icon size="50" color="black">mdi-twitter</v-icon>
            </v-btn>
          </div>
      </div>
        
      </v-card-body>
    </v-card>

     

    
  </v-container>
</template>



<script>
import KontaktFormular from '../components/KontaktFormular.vue'
export default {
  components: { KontaktFormular },
  data: () => ({
    
  })
}
</script>

<style scoped></style>