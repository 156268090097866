<template>
    <v-card outlined elevation="6">
        <v-alert
            v-model="alert.value"
            :type="alert.type"
            transition="scale-transition"
        >
            {{alert.text}}
        </v-alert>
        <v-card-title>
            Kontaktformular
        </v-card-title>
        <v-card-text>
            <v-form v-model="valid" ref="form">
                <v-container>
                    <v-row>
                        <v-text-field outlined
                            v-model="email"
                            :rules="[required, rules.email]"
                            placeholder="email@example.com"
                            label="E-mail"
                            prepend-inner-icon="mdi-at"
                        />
                    </v-row>
                    <v-row>
                        <v-text-field outlined
                            v-model="telefon"
                            :rules="[required, rules.telefon]"
                            placeholder="+49123 456789"
                            label="Telefon"
                            prepend-inner-icon="mdi-cellphone"
                        />
                    </v-row>
                    <v-row>
                        <v-textarea outlined
                            prepend-inner-icon="mdi-comment"
                            label="Nachricht"
                            placeholder="Geben Sie hier ihre Nachricht ein..."
                            v-model="nachricht"
                        />
                    </v-row>
                    <v-row>
                        <v-checkbox v-model="allow" class="mt-0" :rules="[allow]">
                            <template slot="label">
                                Ich habe die Datenschutzerklärung zur Kenntnis genommen und 
                                willige in die entsprechende Speicherung meiner Daten zu Zwecken 
                                der Bearbeitung der Anfrage bzw. Kontaktaufnahme ein.
                            </template>
                        </v-checkbox>
                    </v-row>
                    <v-row class="d-flex flex-row-reverse">
                        <v-btn style="min-width:200px"
                            :disabled="!valid"
                            color="success"
                            class="mr-4"
                            @click="processForm"
                        >
                            Senden
                        </v-btn>
                        <v-btn class="mr-5" to="datenschutz">Datenschutz</v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "KontaktFormular",
    computed:{
        required: function(){
            return this.email != "" || this.telefon != ""
        }
    },
    data(){
        return{
            email: "",
            nachricht: "",
            telefon: "",
            allow: false,

            valid: false,
            rules: {
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return value == "" || pattern.test(value) ? true : 'E-Mail ungültig'
                },
                telefon: value => {
                    const pattern = /^([+](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,8}$/
                    return value == "" || pattern.test(value) ? true : 'Telefonnummer ungültig'
                },
                allow: value => value == true
            },
            loading: false,

            alert:{
                value: false,
                type: "success",
                text: "default",
                timeout: null
            }
        }
    },
    methods:{
        async processForm(){
            if(!this.$refs.form.validate())
                return;
            this.loading = true
            var res = await this.submit()
            clearTimeout(this.alert.timeout)
            this.alert = {
                value: true,
                type: res.status < 300 ? "success" : "error",
                text: res.responseText,
                timeout: setTimeout(() => this.alert.value = false, 5000)
            }
            this.loading = false
        },
        submit(){
            return new Promise(resolve => {
                var data = new FormData()
                data.append("email", this.email)
                data.append("telefon", this.telefon)
                data.append("nachricht", this.nachricht)

                var request = new XMLHttpRequest()
                request.onreadystatechange = () => {
                    if(request.readyState == 4)
                        resolve(request)
                }
                request.open("POST", "http://localhost/php/processinfo.php")
                request.send(data)
            })
        },
        reset(){
            this.email = ""
            this.nachricht = ""
            this.telefon = ""
        }
    }
}
</script>